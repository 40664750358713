.funds{
    --block-background: #f2faff;
    --block-text-color: var(--text-primary);
    background: var(--block-background);
    color: var(--block-text-color);
    clear: both;
    padding-top: var(--space-between-blocks);
    padding-bottom: var(--space-between-blocks);
}
.funds img{
    /* width: 70px; */
}
.icon_m2
{
  width: 170px;
  height: 170px;
  border-radius: 100%;
  background: #fff;
  margin: 10px auto;
  padding: 10px;
  border: solid 2px #F9991E;
  text-align: center;
  box-shadow: -1px 7px 10px 0px #cccccc7a;
}
.icon_m2 img
{
  text-align: center;
  margin: 39px auto !important;
  display: block;
  height: 70px;
  width: auto;
  
}



@media screen and (max-width: 992px) {
    .funds{
      /* padding: 4.3rem; */
    }
  }
  
  
  
  @media screen and (max-width: 620px) {
    .funds{
      /* padding: 1rem; */
    }
  }
  
  @media screen and (max-width: 567px) {
    .funds{
      /* padding: 0rem; */
    }
  }