.summary{
    padding-top: 15px;
    padding-bottom: 15px;
    background: #F2F7FF !important;
    max-width: 80%;
    border-radius: 0px 10px 10px 0;
    margin-top: -30px;
    position: relative;
}
.summary_data{
    border-right: solid 1px #ACC7E5; 
}
.summary_data img{
    width: 40px;
    margin-bottom: 10px;
}
.summary_data h3{
    color: #747491;
    font-size: 24px;
    font-weight: 700;
}
.summary_data p{
    font-weight: 600;
    font-size: 16px;
    color: #747491;
    margin: 0;
}
.summary_data:last-child{
    border: none;
}



@media screen and (max-width: 576px) {
    .summary_data{
      margin-bottom: 2rem;
      border: none;
    }
  }