.testimonials{
    position: relative;
   padding-top: 30px;
   padding-bottom: 30px;
}


.testimonials .swiper {
    width: 100%;
    height: 100%;
  }
  
  .testimonials .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonials .swiper-slide img {
    display: block;
    width: 100%;
     height: 100%; 
    /* object-fit: cover; */
    /* object-fit: contain; */
    object-fit: inherit;
   min-height: 280px; 
  }
  .testimonial__body-test{
    text-align: left;
  }
  .testimonial__body .SwiperSlide{
    /* align-items: start !important; */
  }
  .testimonial__body-test{
    padding-left: 30px;
  }
  .testimonial__body-test p{
    color: #747491
  }
  .testimonial__body-test a{
    text-decoration: none;
    color: #F9991E!important;
    font-size: 16px;
    font-weight: 500;
  }
  .block__header{
    margin-bottom: 4rem;
  }
  .block__title {
    font-size: 2.5rem;
}
.block__paragraph {
    font-size: 1.1rem;
    line-height: 1.5;
    opacity: .9;
}
.card_m1{
  text-align: center;
}
.testimonial__body .card_m4 {
    margin: 0 auto;
    box-shadow: 1px 0px 4px 2px #cccccc29;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 25px;
    width: 90%;
    overflow: hidden;
}
.testimonial__body .mySwiper{
   border-radius: 10px;
}








@media screen and (max-width: 992px) {
  .testimonials{
    padding: 4.3rem;
  }
}

@media screen and (max-width: 767px) {
  .testimonial__body-test{
    padding: 26px;
  }
}

@media screen and (max-width: 620px) {
  .testimonials{
    padding: 1rem;
  }
}

@media screen and (max-width: 567px) {
  .testimonials{
    padding: 0rem;
  }
}