.header-section .navbar-nav
{
    padding-left: 20px;
}
.logo a
{
    display: flex;
}
.logo img{
    
    /* height: 140px;
    position: absolute;
    top: 0; */
    width:75px;
  }
  .nav__menu a{
    text-decoration: none !important;
    color: #000 !important;
    opacity: .9;
    text-align: center;
    font-size: 1.1rem;
    padding: 5px 0px !important;
    margin: 0px 13px;
    position: relative;
    font-size: 18px;
    /* font-weight: 500; */
    
  }
  .nav__menu a.active {
    color: #ff9600 !important;
    font-weight: 600;
}
.nav__menu a.active::before{
    content: '';
 position: absolute;
 bottom: 0px;
 height: 4px;
 border-radius: 11px;
 width: 100%;
 background: #ff9600;
}

.header__btns .btn1{
    background: #2D8DCD;
    color: #fff!important;
    padding: 5px 15px !important;
    border-radius: 5px !important;
    border: none;
    margin-right: 20px;
}

.header__btns .btn2{
    background: #206DC6;
    color: #fff!important;
    padding: 5px 15px !important;
    border-radius: 5px !important;
    border: none;
}
.header-section {
    transition: 1s;
    background: #fff;
    padding: 0 !important;
}
.is-sticky {
    /* position: fixed !important; 
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px !important; 
    padding-bottom: 0px!important;  */
   
}
@media only screen and (max-width: 992px){
    .nav__menu a{
        text-align: left;
        padding: 10px 0px !important;
    }
    
}