.block__text{
    z-index: 99;
    bottom: 0;
    left: 30px;
    color: #fff;
    max-width: 500px;
}
.block__text ul{
    padding: 0px !important;
    list-style: none;
}
.block__text ul li{
display: inline-block;
margin-right: 20px;

}
.block__text h5{
    font-weight: 700;
}
.blogs__Banner-right{
    padding: 30px 40px !important;
    background-color: #F9991E;
    color: #fff;
}
.btn_sub{
    background-color: #fff;
    border: none;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
    color: #F9991E;
}
.blogs__Banner-right h4{
font-weight: 600;
}