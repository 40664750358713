.organisation__reg .btn_m3{
  /* padding: 10px 40px !important;
  font-size: 20px; */
}
.organisation__reg form{
    /* padding: 0px 60px; */
}

@media screen and (max-width: 992px) {
    .organisation__reg form{
        padding: 0px;
    }
}