.forgot__password {
text-align: right;
color: #2D8DCD;
padding-top: 10px;
}
.form__btns .btn1{
    background: #2D8DCD;
    color: #fff!important;
    padding: 5px 15px !important;
    border-radius: 5px !important;
    border: none;
    margin-right: 12px;
}
.form__btns .btn2{
    background: #F9991E;
    color: #fff!important;
    padding: 5px 15px !important;
    border-radius: 5px !important;
    border: none;
    margin-left: 12px;
}
.contribute a{
    /* color: #F9991E !important; */
}