
.hero__scn{
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;
}

.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }
  
  .autoplay-progress {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 10;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--swiper-theme-color);
  }
  
  .autoplay-progress svg {
    --progress: 0;
    position: absolute;
    left: 0;
    top: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    stroke-width: 4px;
    stroke: var(--swiper-theme-color);
    fill: none;
    stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
    stroke-dasharray: 125.6;
    transform: rotate(-90deg);
  }
  .hero__scn .swiper-button-next,
  .hero__scn .swiper-button-prev{
    background: none !important;
    color: #000 !important;
    box-shadow: none;
    font-size: 20px !important;
  }




.hero__scn{
    text-align: center;
    justify-content: center;
    align-items: center;
}
.hero__text{
    text-align: left;
}
.hero__text h1{
    color: var(--secondary);
    font-size: 54px;
    line-height:1.27;
}
.hero__text h1 span{
    color: var(--primary);
}
.hero__text p{
    opacity: 0.9;
    font-size: 1rem;
}
.hero__text .btns{
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
}
.hero__text .btn_m2{
    background:  var(--primary);
    margin-right: 20px;
}
.hero__text .btn_m3{
    background: #F9991E;
}
.carousel-caption{
 
  right: inherit !important;
  top: 15% !important;
  left: 5% !important;
  color: #000 !important;
}
.carousel-control-next-icon, .carousel-control-prev-icon
{
  border-radius: 100%;
  height: 40px !important;
  width: 40px !important;
  text-align: center;
  font-size: 15px;
  background-size: 24px !important;
  background-repeat: no-repeat !important;
  opacity: 1;
  text-align: center;
  background-position: center !important;
  background-color: #ff9600;
}


@media screen and (max-width: 992px) {
  .block__title{
    font-size: 2rem !important;
    margin-top: 0px;
  }
}

 