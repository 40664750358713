
.featured{
    position: relative;
    padding: var(--space-between-blocks);
    padding-bottom: var(--space-between-blocks);
    /* background: linear-gradient(180deg, rgba(255,255,255,1) 47%, rgba(245,245,245,1) 47%)!important; */
}
.featured::before{
  /* clip-path: polygon(32% 34%, 56% 51%, 100% 35%, 100% 100%, 70% 100%, 30% 100%, 0 100%, 0 50%);
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 700px;
  background: #F2F7FF;
  left: 0; */
}
/* .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 16px;
    background: transparent;
  
     display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .swiper-slide img {
    display: block;
    width: 100%;
    /*  height: 100%;
   object-fit: cover;
    max-height: 180px; */
  }
  .featured .swiper-button-next,
  .featured .swiper-button-prev {
    /* top: -100px; */
    background: #F2FAFF !important;
    color: #206DC6;
    width: 45px;
    height: 45px;
    border-radius: 30px;
    box-shadow: 0px 2px 8px 0px #cccccc9c;
  }
  .featured .swiper-button-prev:after, .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    font-size: 28px !important;
  }
  .featured .swiper-button-next:after, .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    font-size: 28px !important;
}
.featured .swiper-pagination-bullet{
    display: none;
}
.swiper-slide
{
  background: transparent;
}



.featured__card-body{
    text-align: left;
}
  .featured__card h5{
    color: #2E3533 !important;
    font-weight: 600 !important;
    white-space: nowrap;
    text-align: left !important;
  }
  .featured__card .rows p{
    font-size: 9px;
    white-space: nowrap;
    text-align: left;
  }
  .circle1{
    position: relative;
    margin-top: 15px;
    text-align: left;
  }
  .circle1 span{
    display: inline-block;
    margin-right: 5px;
    width: 38px;
    height: 38px;
    background: #fff;
    border-radius: 100%;
    text-align: center;
    line-height: 36px;
    color: #206DC6;
    font-weight: 600;
    font-size: 14px;
    border: solid 3px #81869C;
  }
  .circle1 i{
    position: relative;
    margin-top: 15px;
     
    color: #81869C;
    font-size: 15px;
    font-style: inherit;
  }
  .btn_m3{
    /* background: #F9991E !important;
    color: #fff!important;
    padding: 5px 20px !important;
    border-radius: 5px !important;
    margin-right: 10px; */
}
.btn_m2{
    background: #2D8DCD !important;
    color: #fff!important;
    padding: 5px 15px !important;
    border-radius: 5px !important;
}
.featured__card{
    background: #fff;
    /* box-shadow: 3px 10px 20px 5px #ccc;
    margin-bottom: 30px; */
    padding-top: 0;
    border-radius: 15px !important;
    padding-top: 0;
    overflow: hidden;
}
.block__header{
    margin-bottom: 4rem;
}
.featured__card-body{
    padding: 15px;
}
.block__title {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.4;
}
.block__title span{
    color: var(--primary);
}
.block__paragraph{
    font-size: 1.1rem;
    line-height: 1.5;
    opacity: .9;
}
.featured .swiper-initialized{
  /* padding-top: 120px;
  margin-top: 24px; */
  position: relative;
}
.featured .swiper-button-next{
    /* top: 23px;
    z-index: 999;
    right: 10px; */
    right: 2px;
}
.featured .block__header{
  /* margin-bottom: -100px; */
}
.featured .swiper-button-prev{
  /* top: 24px;
  left: inherit;
  right: 70px; */
  left: 2px;
}
.featured .swiper-button-disabled
{
  background: #fff !important;
  color: #000 !important;
}
.featured__card-head
{
  position: relative;

}

/* common */
.ribbon {
  width: 130px;
  height: 130px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2DC620;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 195px;
  padding: 6px 0;
  background-color: #2DC620;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 400 14px/1 'Lato', sans-serif;
   
  text-align: center;
}
 

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -18px;
  top: 37px;

  transform: rotate(45deg);
}
.banner_cap
{
  background: #0000009e;
  display: inline-block;
  padding: 3px 10px;
  position: absolute;
  bottom: 0;
  margin: 0;
  left: 0;
  color: #fff;
  font-size: 15px;
  min-width: 110px;
}
.featured .swiper-button-next,
.featured .swiper-button-prev{
  color: #206DC6  !important;
}
.featured .swiper-button-next.swiper-button-disabled,
.featured .swiper-button-prev.swiper-button-disabled{
  color: #000  !important;
  opacity: 1;
}

.featured .swiper-button-next:after,
.featured .swiper-rtl .swiper-button-prev:after{
  /* font-size: 20px !important; */
}




@media screen and (max-width: 992px) {
  .featured{
    padding: 4.3rem;
  }
}

@media screen and (max-width: 620px) {
  .featured{
    padding: 1rem;
  }
}

@media screen and (max-width: 567px) {
  .featured{
    padding: 0rem;
  }
}


.mySwiper1,
.list_m4
{
  height: 300px;
  /* width: 300px; */
}