:root {
  --primary: #2D8DCE;
  --secondary: #0a1f44;
  --primary-invert: #fff;
  --secondary-invert: #fff;

   /* DEFAULT COLOR OF ALERT TEXT */
   --alert-text-primary: #fff;
   
  /* DEFAULT COLOR OF TEXTS */
  --text-primary: #0a1f44;
  
  /* FOR BUTTONS & INPUTS */
  --elements-roundness: 10rem;

  --space-between-blocks: 5.3rem;
  /* for mobiles */
  --space-between-blocks-small-screens: 3rem;
}

body {
  font-family: 'Poppins', sans-serif;
  color: var(--alert-text-primary);
}

*{
  margin: 0px;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
}
h1, h2, h3, h4, h5, h6, p{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
  color: unset;
}
ul{
  list-style: none;
}
