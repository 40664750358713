:root {
  --primary: #ff9600;
  --secondary: #0a1f44;
  --primary-invert: #fff;
  --secondary-invert: #fff;

  /* DEFAULT COLOR OF ALERT TEXT */
  --alert-text-primary: #fff;

  /* DEFAULT COLOR OF TEXTS */
  --text-primary: #0a1f44;

  /* FOR BUTTONS & INPUTS */
  --elements-roundness: 10rem;

  --space-between-blocks: 5.3rem;
  /* for mobiles */
  --space-between-blocks-small-screens: 3rem;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap");

body {
  font-family: "Source Sans Pro", sans-serif !important;
  color: var(--alert-text-primary);
  overflow-x: hidden;
}

* {
  margin: 0px;
  padding: 0px;
  outline: none;
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: unset;
}

ul {
  list-style: none;
}

.highlight {
  color: var(--primary);
}

.block__title {
  font-size: 2.5rem;
}

.block__paragraph {
  font-size: 1.1rem;
  line-height: 1.5;
  opacity: 0.9;
}

p {
  color: #2e3533;
}

.block-41__copyrights {
  color: #fff;
  opacity: 0.8 !important;
}

.header {
  background: #f5f5f5;
  /* box-shadow: 2px 4px 3px #cccccc59; */
  margin-bottom: 2px;
}

.home-layout .header {
  background: #fff;
}

.nav__menu a.active {
  color: #ff9600;
}

.btn_m4 {
  border: solid 2px #ff9600 !important;
  color: #ff9600 !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.btn_m5 {
  background: #ff9600 !important;
  color: #fff !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  border: none !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 29px !important;
  fill: #81869c !important;
}

.c1 {
  color: #f9991e;
}

.c2 {
  color: #5c5c5c;
}

.c3 {
  color: #0a1f44;
}

.c4 {
  color: #555555;
}

.list_m7 {
  padding: 0;
}

.list_m7 span {
  font-weight: 500;
  color: #000;
}

.list_m7 li {
  display: inline-block;
  margin-right: 10px;
  padding-right: 10px;
  font-size: 12px;
  border-right: solid 2px #b0b0b0;
  color: #81869c;
  font-weight: 300;
}

.list_m7 li:last-child {
  border: none;
}

.list_m7 i {
  color: #81869c;
}

.btn_m5,
.btn_m4,
.btn_m3 {
  min-height: 40px;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #2dc620 !important;
}

.gride_m8 {
  width: 20%;
  display: inline-block;
}

.scrollTop {
  display: flex;

  position: fixed;
  bottom: 15px;
  right: 15px;
  z-index: 9;
  /* width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid red; */
  background: #0a1f44;
  padding: 10px;
  width: 40px;
  text-align: center;
  height: 40px;
  border-radius: 100%;
  color: #fff;
  box-shadow: -2px 1px 9px 5px #ccc;
  cursor: pointer;
}

.scrollTop::before {
  content: "\f106";
  font-family: "FontAwesome";
  padding-left: 3px;
  font-size: 24px;
  position: relative;
  top: -8px;
}

.list_m8 {
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.list_m8 li {
  text-align: left;
  padding: 5px 0;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.footer p {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}

.social_icons {}

.social_icons li {
  display: inline-block;
  margin-right: 15px;
}

.social_icons li a {
  color: #fff;
  font-size: 27px;
}

.sel_m4 {
  border: none;
  border-bottom: solid 2px;
  vertical-align: bottom;
  border: solid 1px #81869c;
  padding: 7px 11px;
  border-radius: 10px;
  padding-right: 20px;
}

.featured .swiper-backface-hidden .swiper-slide {
  padding: 25px 0px;
}

.funds .swiper-pagination {
  display: none;
}

.funds .swiper-slide p {
  font-size: 24px;

  font-weight: 500;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-next,
.swiper-button-prev {
  background: #ffffb5;
  border-radius: 100%;
  width: 45px !important;
  text-align: center;
  height: 45px !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 28px !important;
}

.testimonials {}

.block__title,
.block__title .highlight {
  font-size: 36px !important;
  color: #333333;
  font-weight: 600 !important;
}

.banner_content1 .block__title .highlight,
.banner_content1 .block__title {
  font-weight: 700 !important;
}

.banner_content1 .block__title .highlight {
  color: #ff9600;
}

.color_1 {
  color: #747491;
  font-size: 18px !important;
  font-weight: 500;
}

.banner_content1 .color_1 {
  font-size: 20px !important;
}

.fweight_500 {
  font-weight: 500;
}

.fweight_600 {
  font-weight: 600;
}

.font_26 {
  font-size: 26px;
}

.font_16 {
  font-size: 16px;
}

/* Switch Icon */
.custom_switch .switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 15px;
  vertical-align: middle;
}

.custom_switch .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom_switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.custom_switch .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 0px;
  bottom: -3px;
  background-color: white;
  transition: 0.4s;
  box-shadow: 1px 0px 5px 2px #ccc;
}

.custom_switch input:checked+.slider {
  background-color: #2196f3;
}

.custom_switch input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.custom_switch input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.custom_switch .slider.round {
  border-radius: 34px;
}

.custom_switch .slider.round:before {
  border-radius: 50%;
}

/* Switch Icon */
.social_links {
  padding: 0px;
}

.social_links li {
  display: inline-block;
  margin-right: 10px;
}

.social_links li a img {
  filter: invert(100%);
}

.banner_set1 {
  position: relative;
}

.banner_set1 .content_m2 {
  position: absolute;
  top: 0px;
  /* background: url(../src/assets/images/rectangle1.png); */
  height: 100%;
  max-width: 700px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding-top: 150px;
  padding-left: 85px;
  padding-right: 50px;
}

.shape_3 {
  position: relative;
}

.shape_3::before {
  position: absolute;
  background: url(../src/assets/images/shape3.png);
  width: 100%;
  height: 150px;
  bottom: -150px;
  content: "";
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.shape_m5 {
  position: relative;
  padding-top: 0px;
}

.shape_m5::before {
  position: absolute;
  /* background: url(../src/assets/images/rectangle_shape.png); */
  z-index: -1;
  width: 100%;
  min-height: 600px;
  content: "";
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.shape_m2 {
  position: relative;
  min-height: 700px;
}

.shape_m2::before {
  clip-path: polygon(0 0, 100% 0, 100% 50%, 60% 46%, 30% 56%, 0 49%);

  background: #f2f7ff;
  content: "";
  position: absolute;
  height: 1200px;
  width: 100%;
  z-index: -1;
}

.fa {
  display: inline-block;

  font-family: FontAwesome !important;
}

.btn_m7 {
  color: #ff9600;
  font-weight: 500;
  outline: none;
}

.btn_m8 {
  color: #ff9600;
  font-weight: 500;
  outline: none;
  background: transparent;
  border: none;
}

.input_m7 {
  width: 100%;
}

.input_m7 input,
.input_m7 select {
  width: 100%;
  background: transparent;
  border: none;
  border: solid 1px #747491;
  padding: 7px;
  border-radius: 10px;
}

.list_m9 img {
  margin: 0px 10px;
}

.bg_m5 {
  background: #c7f4f4;
  position: relative;
}

.bg6 {
  background: #f2f7ff;
}

.bg5_img {
  margin-bottom: 120px;
}

.bg5_img::after {
  content: "";
  position: absolute;
  bottom: -100px;
  width: 100%;
  height: 100px;
  background: url(../src/assets/images/banner_shape.png);
  background-size: cover;
}

.sub_banner img {
  border: solid 2px #81869c;
  border-radius: 5px;
}

.size_14 {
  font-size: 12px;
}

.gride_m7 {
  border: solid 1px #81869c;
  border-radius: 10px;
  padding: 15px;
  position: relative;
  padding-left: 70px;
}

.user_icon1 {
  width: 50px;
  height: 50px;
  border: solid 1px #81869c;
  text-align: center;
  border-radius: 100%;
  position: absolute;
  left: 10px;
}

.size_14 {
  font-size: 14px;
}

.card_m5 {
  border: solid 1px #81869c;
  border-radius: 10px;
  overflow: hidden;
}

.card_m5 h5 {
  background: #ecf1f9;
  border-bottom: solid 1px #81869c;
}

.card_m5 li {
  position: relative;
  display: flex;
  width: 100%;
  border-bottom: solid 1px #c7c7c7;
  padding: 10px 0;
}

.card_m5 li span {
  width: 50px;
  height: 50px;
  border: solid 1px #81869c;
  border-radius: 100%;
  margin-right: 10px;
  text-align: center;
  display: inline-block;
  line-height: 50px;
  color: #ff9600;
  min-width: 50px;
}

.card_m5 li p {
  margin: 0px;
  width: 100%;
  font-weight: 400 !important;
}

.card_m5 li small {
  white-space: nowrap;
}

.sel_m4_parent {
  position: relative;
}

.sel_m4_parent::before {
  content: "";
  position: absolute;
  height: 60%;
  width: 1px;
  right: 23px;
  background: #000;
  top: 20%;
}

.banner_content1 {
  padding-left: 60px;
}

.banner_content1 button {
  min-width: 200px;
}

.funds .swiper-button-next,
.funds .swiper-rtl .swiper-button-prev,
.funds .swiper-button-next,
.funds .swiper-button-prev {
  font-weight: bolder;
  color: #041766 !important;
  margin-top: -60px !important;
}

.custom_switch p {
  color: #000 !important;
}

.tabs_m1.nav.nav-tabs {
  border-bottom: solid 2px #000;
}

.tabs_m1 li.nav-item {
  margin-bottom: -1px;
}

.tabs_m1 .nav-link.active {
  border-bottom: solid 4px #ff9600 !important;
  background: transparent;
  color: #ff9600 !important;
  margin-bottom: 0px;
}

.tabs_m1 .nav-item button {
  border: none;
  color: #5c5c5c;
  font-weight: 600;
  font-size: 17px;
}

.steps_campaign {
  position: relative;
  padding: 30px 0px;
}

.steps_campaign::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 100%;
  background: #d9d9d9;
  border-radius: 10px;
  left: 50%;
  top: 0;
}

.steps_campaign li {
  position: relative;

  background: #fff;
  margin-bottom: 15px;
  width: 45%;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 2px 5px 11px 5px #cccccc54;
}

.steps_campaign li::after {
  background: #dbdbdb;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  top: 9px;
  z-index: -1;
  left: 10px;
  border-radius: 8px;
}

.steps_campaign li::before {
  content: "";
  width: 36px;
  height: 36px;
  border-radius: 100%;
  position: absolute;
  right: -88px;
  background: #fff;
  border: solid 3px #ccc;
}

.steps_campaign li:nth-of-type(odd) {
  /* margin: 0 0 0 auto; */

  padding-right: 60px;
}

.steps_campaign li:nth-of-type(even) {
  padding-left: 60px;
  text-align: right;
  margin: 0 0 0 auto;
}

.steps_campaign li h5 {
  font-size: 24px;
  margin: 0px;
  margin-bottom: 10px;
  font-weight: 600;
}

.steps_campaign li p {
  color: #81869c;
}

.steps_campaign span {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 48px;
  color: #d9d9d9;
  font-weight: 600;
}

.steps_campaign li:nth-of-type(even) span {
  right: inherit;
  left: 10px;
}

.steps_campaign li:nth-of-type(even)::after {
  left: inherit;
  right: 10px;
}

.steps_campaign li:nth-of-type(even)::before {
  left: inherit;
  left: -77px;
}

.video_m2 video {
  width: 100%;
}

.shape_height_300 {
  min-height: auto;
  margin-bottom: 300px;
}

.acc_m2 .MuiCollapse-root .MuiCollapse-entered,
.acc_m2 .MuiPaper-root .Mui-expanded,
.acc_m2 .MuiAccordionDetails-root {
  background: #f2f7ff !important;
  border: none !important;
}

.acc_m2 .MuiAccordionSummary-content.Mui-expanded.css-1betqn-MuiAccordionSummary-content p,
.acc_m2 .MuiAccordionSummary-expandIconWrapper.Mui-expanded.css-yw020d-MuiAccordionSummary-expandIconWrapper {
  /* color: #F9991E; */
}

.slider_m2 .content_m3 {
  position: absolute;
  bottom: 15px;
  background: #ffffffe3;
  padding: 15px;
  max-width: 80%;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
  left: 15px;
}

.slide_m4 {
  margin-bottom: 40px;
  position: relative;
}

.slider_m2 img {
  border-radius: 0px;
}

.slider_m2 .swiper-pagination {
  text-align: right;
  bottom: -25px;
}

.slider_m2 .swiper-pagination-bullet-active {
  background: var(--primary);
}

.slider_m2 .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  box-shadow: 2px 2px 2px #ccc;
}

.card_m2 {
  background: #fff;
  box-shadow: -1px 5px 14px 3px #00000036;
}

.team_member {
  position: relative;
  /* padding-right: 50px; */
}

.team_member .name_desi {
  background: #ffffff;
  border-right: solid 5px #f9991e;
  font-size: 20px;
  padding: 15px;
  box-shadow: 0px 0px 8px 2px #dcdcdc;
}

.team_member .name_desi p:first-child {
  color: #f9991e;
}

.gallery_item {
  background: #ebebeb;
  margin-bottom: 20px;
  cursor: pointer;
}

.gallery_item img {
  max-height: 200px;
  opacity: 0;
}

.banner_m3 {
  background: url(../src/assets/images/tems-banner.png) no-repeat;
  background-size: cover;
  min-height: 600px;
  position: relative;
}

.banner3_content {
  background: #ffffffe3;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  bottom: -50px;
  position: absolute;
  max-width: 700px;
  width: 100%;
  box-shadow: 0px 3px 10px 5px #cccccc2e;
}

.c5 {
  color: #000 !important;
}

.donate__fome .input_m2sup .sel_m4 {
  border-radius: 0;
}

.swiper-button-prev:after {
  content: "\f104" !important;
  font-family: "FontAwesome" !important;
}

.swiper-button-next:after {
  content: "\f105" !important;
  font-family: "FontAwesome" !important;
}

.header-section .btn-outline-success {
  min-width: 104px;
}

.tabs_m4 .rts___tab {
  background: #ffffff !important;
  border: none;
  color: #0e0e0e;
  border-radius: 8px;
  box-shadow: 1px 2px 5px #ccc6;
  padding: 7px 24px;
  font-size: 19px;
}

.tabs_m4 .rts___tab.rts___tab___selected {
  background: #f9991e !important;
  color: #fff;
}

.tabs_m4 .some-animation-class {
  margin-top: 40px;
}

.rts___tabs {
  margin: 0 auto;
}

.rts___tab.rts___btn.rts___tab___selected {
  position: relative;
}

.rts___tab.rts___btn.rts___tab___selected::before {
  /* content: "\f058";
  font-family: "FontAwesome";
  position: absolute;
  left: 7px;
  font-size: 17px;
  top: 7px; */
}

.pop_m1 .col-md-6 {
  /* width: 100%;
  max-width: 100%;
  margin: 0 auto; */
  margin-left: 0px;
}

.pop_m1 .col-md-6.offset-md-3 {
  width: 100%;
}

.pop_m1 .circle_m4 {
  display: none;
}

.pop_m1 .card.card-body.shadow {
  border: none;
  box-shadow: none !important;
}

.pop_m1 .modal-body,
.pop_m1 .signin.py-5 {
  padding: 0 !important;
}

.link_m3 {
  color: #ff9600 !important;
}

.cat_list1 {
  display: inline-block;
  width: calc(20% - 40px);
  text-align: center;
  background: #fff;
  margin: 20px;
  min-height: 120px;
  vertical-align: top;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
  transition: 1s;
  min-width: 130px;
}

.cat_list1 img {
  filter: grayscale(0.9);
  margin: 0 !important;
  max-height: 60px;

  min-height: 60px;
}

.cat_list1:hover {
  transform: scale(1.2);
  box-shadow: 1px 1px 16px 1px #c6d5ed;
}

.cat_list1:hover img {
  filter: inherit;
}

.cat_list1:hover p {
  color: #ff9600;
}

.cat_list1 p {
  margin: 0px;
  font-weight: 600;
}

.bg_gradent1 {
  background: linear-gradient(88.09deg, #ffffff 27.51%, #ffffb5 71.51%);
}

.shape_m3 {
  /* background: url(../src/assets/images/rectangle_shape.png); */
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top;
  padding: 50px 0;
  margin-top: -80px;
  z-index: 1;
  position: relative;
}

.bg_m8 {
  background: #ffffb5;
}

.c6 {
  color: #ff9600;
}

.acc_m2 .MuiAccordionDetails-root .MuiTypography-body1 {
  background: #fff;
  padding: 10px;
}

.acc_m2 .MuiButtonBase-root.MuiAccordionSummary-root {
  padding-bottom: 0 !important;
}

.barh_m1 {
  height: 8px;
}

.list_m7sup1 li {
  font-size: 16px;
  font-weight: 500;
}

.off_bg {
  /* background: rgb(242,247,255);
background: -moz-linear-gradient(0deg, rgba(242,247,255,1) 51%, rgba(255,255,255,1) 51%);
background: -webkit-linear-gradient(0deg, rgba(242,247,255,1) 51%, rgba(255,255,255,1) 51%);
background: linear-gradient(0deg, rgba(242,247,255,1) 51%, rgba(255,255,255,1) 51%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f2f7ff",endColorstr="#ffffff",GradientType=1); */
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(180deg,
      rgba(255, 255, 255, 0) 65%,
      rgba(242, 250, 255, 1) 65%);
  background: -webkit-linear-gradient(180deg,
      rgba(255, 255, 255, 0) 65%,
      rgba(242, 250, 255, 1) 65%);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 65%,
      rgba(242, 250, 255, 1) 65%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffb5", GradientType=1);
}

.featured__card-head img {
  max-height: 240px;
}

.slider_m2 .swiper-pagination {
  text-align: center;
}

.barh_m1 {
  height: 8px !important;
}

.barh_m1 .progress-bar {
  background: #ff9600;
}

.barh_m1 {
  background: #d9d9d9 !important;
}

.banner_tr1 {
  position: relative;
}

.banner_tr1::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(180deg,
      rgba(255, 255, 255, 0) 37%,
      rgba(0, 0, 0, 0.8156512605042017) 73%);
  background: -webkit-linear-gradient(180deg,
      rgba(255, 255, 255, 0) 37%,
      rgba(0, 0, 0, 0.8156512605042017) 73%);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 37%,
      rgba(0, 0, 0, 0.8156512605042017) 73%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#000000", GradientType=1);
}

.card_m6 {}

.list_m10 {
  margin: 0px;
  padding: 0;
}

.list_m10 li {
  display: inline-block;
  padding-right: 12px;
}

.imp_star label {
  width: auto;
  padding-right: 15px;
}

.imp_star label::after {
  /* content: "*";
  color: #ff0000; */
}

.float_input {
  position: relative;
}

.float_input img {
  position: absolute;
  height: 15px;
  z-index: 1;
  top: 16px;
  left: 4px;
}

.float_input .fHgjCR {
  padding-left: 16px;
}

.c_5 {
  color: #ff0000;
}

.circle_m4 {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background: #ffffb5;
  position: absolute;
  z-index: -1;
  right: -60px;
  top: 0;
}

.circle_m4::after {
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: #ffffb5;
  position: absolute;
  right: -80px;
}

.left_align {
  transform: rotate(237deg);
  right: inherit;
  left: -60px;
  top: inherit;
  bottom: 50px;
}

.btn_m6 {
  background: #256593;
  border: none;
  border-radius: 25px;
}

.btn_m7 {
  background: #cd3225;
  border: none;
  border-radius: 25px;
}

.float_input .form-control {
  border: none;
  border-radius: 0px;
  border-bottom: solid 1px #ccc;
  padding: 0;
  height: 41px !important;
  line-height: 41px !important;
  padding-left: 20px !important;
  box-shadow: none !important;
  outline: none !important;
}

.float_input .form-floating>label {
  padding: 0;
 
  height: auto;
  padding-left: 22px;
  top: 10px;
}
.float_input input
{
  padding-top: 6px !important;
  padding-bottom:0px !important;
  height: 30px !important;
    line-height: 30px !important;
    min-height: 30px !important;
}
.float_input .form-floating>label::after{
  display: none !important;
}
.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label
{
  transform: scale(.85) translateY(-1rem) translateX(.15rem) !important;
}

.line_g {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(180deg,
      rgba(255, 255, 255, 0) 2%,
      rgba(164, 164, 164, 0.8156512605042017) 50%,
      rgba(255, 255, 255, 1) 98%);
  background: -webkit-linear-gradient(180deg,
      rgba(255, 255, 255, 0) 2%,
      rgba(164, 164, 164, 0.8156512605042017) 50%,
      rgba(255, 255, 255, 1) 98%);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 2%,
      rgba(164, 164, 164, 0.8156512605042017) 50%,
      rgba(255, 255, 255, 1) 98%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);

  width: 2px;
  display: inline-block;
  float: left;
  position: absolute;
  top: 0;
  margin-left: -24px;
  height: 80%;
  top: 10%;
}

.pop_m1 .modal-header {
  position: absolute;
  z-index: 1;
  right: 0;
  border: none;
  box-shadow: 0px 0px 6px 2px #ccc;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  margin-top: -9px;
  background: #fff;
  margin-right: -10px;
}

.pop_m1 .modal-header .btn-close {
  margin-left: -7px;
}

.featured .swiper-initialized {
  padding: 0px 50px;
}

.banner_content1 .link_m3,
.about__banner-text .link_m3 {
  text-decoration: underline !important;
}

/* .banner_m1 .carousel-inner
{
  height: 450px;
} */

.banner_m1 .carousel-control-prev,
.banner_m1 .carousel-control-next {
  top: 50%;
  padding: 10px;
  height: max-content;
  width: auto;
}

.news_list .swiper-slide {
  text-align: left;
  border-bottom: solid 1px #ccc;
}

.carousel-indicators [data-bs-target] {
  background-color: #ff9600 !important;
}

.marquee_m1 {
  background: #ff9600;
  color: #fff;
  padding: 7px 7px 7px 90px;
  display: -webkit-box;
  font-size: 18px;
  position: relative;
  z-index: 9;
  width: 100%;
  top: 0;
}

.max_width_200 {
  max-width: 200px;
}

.marquee_m1 marquee {
  display: block;
}

.marquee_m1 marquee ul {
  margin: 0px;
}

.marquee_m1 marquee li {
  display: inline-block;
  margin-left: 30px;
  border-left: solid 1px white;
  padding-left: 30px;
}

.marquee_m1 marquee li:first-child {
  border: none;
}

.marquee_m1 span {
  background: #000;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  padding: 7px;
  font-weight: 600;
}

.bg_m4 {
  background: #ffffed;
}

.list_m4 a {
  display: block;
  width: 100%;
  border-bottom: solid 1px #ccc;
  padding: 10px 0;
}

.date_m1 {
  display: block;
  text-align: center;
  background: #d8d8d875;
  width: 60px;
  border-radius: 4px;
  font-size: 17px;
  line-height: 22px;
}

.date_m1 span {}

.list_m4 a p {
  width: 100%;
  margin: 0px;
}

.btn_m3 {
  background: #ffffff !important;
  color: #ff9600 !important;
  padding: 5px 15px !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border: solid 3px #ff9600 !important;
}

.list_m4_sup1 {
  overflow-y: auto;
  overflow-x: hidden;
}

.card_m5 {
  padding: 0;
  border-radius: 5px;
  border: solid 1px #e4e4e4;
  margin: 5px;
  overflow: hidden;
}

.list_m14 {
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
  padding: 0;
}

.list_m14 li {
  border: solid 1px #f0f0f0;
  margin-bottom: 15px;
  padding: 10px;
  overflow: hidden;
  background: #fff;
}

.list_m14 li h3 {
  font-weight: 600;
  font-size: 22px;
}

.list_m14 li p {
  margin: 0;
}

.list_m15 {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.list_m15 li {
  padding: 10px;
  padding-left: 85px;
  position: relative;
  background: #ffffff;
  margin-bottom: 15px;
  border-radius: 8px;
  min-height: 65px;
  border: solid 1px #efefef;
  cursor: pointer;
}

.list_m15 li:hover .images_gride {
  position: relative;
}

.list_m15 li:hover .images_gride::after {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111111bf;
  content: "Click here to view gallery";
  top: 0;
  left: 0;
  text-align: center;
  padding-top: 13px;
  color: #fff;
  font-weight: 600;
}

.list_m15 li small {
  display: block;
}

.list_m15 li span {
  position: absolute;
  width: 65px;
  text-align: center;
  background: #fcac39;
  font-size: 15px;
  border-radius: 4px;
  color: #fff;
  overflow: hidden;
  left: 10px;
}

.list_m15 li small:first-child {
  font-size: 13px;
  font-weight: 600;
  background: #ef8d00;
}

.list_m15 li small:last-child {
  padding: 2px;
  font-size: 15px;
}

.drop_m4 .dropdown-menu {
  min-height: 100px;
}

.drop_m4 a {
  display: block;
  margin: 0;
  text-align: left !important;
}

.card_m4 {
  background: #ff9600;
  color: #fff;
}

/* .header-section .drop_m4 > a:hover + .dropdown-menu
{
 display: block;
} */

/* Gallery css start */
.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;

  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 2px;
  margin: 0;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card1>img {
  max-height: 190px;
}

.card1:hover:before {
  transform: scale(21);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover h5 {
  transition: all 0.3s ease-out;
  color: #ffffff;
}

/* Gallery CSS End */

.font-35 {
  font-size: 35px;
}

.banner_m5 {
  position: relative;
  max-height: 470px;
}

.banner_m5 .banner_m5_content {
  position: absolute;
  bottom: 50px;
}

.nhrc_slider p {
  font-size: 16px;
}

.list_m15 li {
  display: flex;
  width: 100%;
}

.list_m15:hover {}

.list_m15 li p {
  width: 100%;
  font-size: 18px;
}

.images_gride {
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  width: 419px;
}

.images_gride.images_gride.images_gride img {
  width: 70px;
  margin-left: 10px;
}

.images_gride.images_gride.images_gride img:first-child {
  margin: 0;
}

.list_m14_sup1 {
  column-count: 1;
}

/* NEW CSS START HERE 05-03-2024 */
.banner_m1 {
  background: url(../src/assets/images/banner_shape_bg.png);
  padding: 50px 0 65px 0;
}

.banner_m1 h1 {
  font-size: 60px;
  font-weight: 800;
  color: #363533;
  line-height: 60px;
}

.banner_m1 h2 {
  font-size: 36px;
  color: #5e5d5c;
}

.banner_m1 .carousel-control-prev,
.banner_m1 .carousel-control-next {
  display: none;
}

.banner_m1 .carousel-control-prev,
.banner_m1 .carousel-control-next {
  top: 50%;
  padding: 10px;
  height: max-content;
  width: auto;
}

.banner_m1 .carousel-inner {
  border: solid 8px #d9d9d961;
  border-radius: 25px 0 25px 0;
  overflow: hidden;
}

.banner_m1 .carousel-item img {
  width: 100%;
height: 480px;


}

.banner_m1 div img {}

.banner_m1 .carousel-indicators {
  margin: 0;
  bottom: -25px;
}

.banner_m1 .carousel-indicators [data-bs-target] {
  background-color: #2f423159 !important;
  height: 6px;
  border-radius: 20px !important;
  border: none;
  width: 40px;
}

.banner_m1 .carousel-indicators .active {
  background-color: #ff9600 !important;
}

.header {
  /*border-bottom: solid 2px #ff9600;*/
  border-bottom: solid 2px #F2f2f2;
  margin: 0;
}

.sub_pages_banner {
  position: relative;
  /* min-height: 350px; */
}

.sub_pages_banner::after {
  position: absolute;
  content: "";
  background: url(../src/assets/images/banner_separator1.png);
  background-size: 100% 100%;
  width: 111px;
  height: 100%;
  left: -50px;
  z-index: 0;
  background-repeat: no-repeat;
}

.sub_pages_banner h2 {
  color: #5e5d5c;
}

.font_24 {
  font-size: 24px;
}

.font_18 {
  font-size: 18px;
}

.testimonial__body .card_m4 {
  background: #ffbf64;
  border-radius: 8px;
  box-shadow: 1px 0 4px 2px #cccccc29;
  margin: 15px auto 25px;
  overflow: hidden;
}

.card_m7 img {
  border-radius: 8px;
}

.card_m7 p {
  font-size: 19px;
  font-weight: 700;
  margin: 0;
  margin-top: 15px;
  color: #5e5d5c;
}

.about__banner-text h1 {
  font-size: 48px !important;
  font-weight: 700 !important;
  text-transform: uppercase;
  color: #5e5d5c;
}

.about__banner-text h5 {
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
  color: #5e5d5c;
}

.logo h3 {
  font-size: 30px;
}

.logo p {
  font-size: 17px;
  font-weight: 700;
}

.sub_level_banner {
  border-bottom: solid 2px #efe1cd;
}

.pr-md-0 {
  padding-right: 0 !important;
}

.card_m6 img {
  max-width: 70% !important;
}

li.date_not_avl {
  padding-left: 10px;
}

.custom_link_m1 {
  cursor: pointer;
  position: relative;
}

/* .custom_link_m1 ul{
    display: none;
    position: absolute;
    left: 0;
    padding: 0;
    text-align: left;
    background: #fff;
    box-shadow: 2px 5px 5px #ccc;
    border-radius: 5px;
   
}
.custom_link_m1:hover ul{
  display: block;
}
.custom_link_m1 ul a{
  display: block;
  margin: 0;
  text-align: left;
  padding: 4px 15px !important;
} */
/* NEW CSS END HERE  05-03-2024 */
.ReactGridGallery_tile-viewport {
  border: solid 2px #000000;
  width: 180px;
  min-width: 210px;
  height: 150px !important;
}

.ReactGridGallery_tile-viewport img {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}

.Awards .ReactGridGallery_tile-viewport {
  height: 500px !important;
  width: 410px;
}

.btn_m5_small {
  min-height: auto;
  padding: 4px 8px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
}

.documents_set .ReactGridGallery {}

/* .documents_set .ReactGridGallery_tile-viewport
{
 width: 300px !important;
 height: 400px !important;
} */


.list_m8 {
  -moz-column-count: 2;
  -moz-column-gap: 20px;
  -webkit-column-count: 2;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 20px;
  padding: 0;
}

.tabs_m4_sup1 p {
  padding-top: 10px;
  margin-top: 25px;
  position: relative;
  /* background: #ffffff;
  border: solid 1px #efefef; 
   text-align: center;
    border-radius: 8px;
   */
  font-weight: 600;
  font-size: 17px;
  border-top: dashed 1px #a3a3a3;
  color: #5e5d5b;




  margin-bottom: 5px;
}

.some-animation-class p:first-child {
  border-top: none;
}

.header .navbar-nav>a,
.header .navbar-nav .dropdown-toggle {
  font-size: 18px;
  margin: 0 5px;
}

.drp_m1 .dropdown-menu {
  padding: 0;
  border-radius: 0;
}

.drp_m1>.dropdown-menu {
  margin-top: 18px !important;
}

.drp_m1 .dropdown-menu a {
  padding: 8px 15px;
  border-bottom: solid 1px #ccc;
}

.drp_m1 .dropdown-menu a:last-child {
  border: none;
}

.drp_m1 .dropdown-menu a:hover {
  background: #f9991e !important;
  color: #fff !important;
}

.drp_m1_sup1 .dropdown .dropdown-menu {
  top: 0;
  right: inherit;
  left: 158px;
}

.drp_m1_sup1 .dropdown-menu .dropdown>.dropdown-toggle.nav-link {
  margin: 0 !important;
  border: none;
  font-size: 16px;
  padding: 8px 15px;
}

.align_justify {
  text-align: justify;
}

.bg_m9 {
  background: #ffec9f;
}

.rts___tabs___container {
  gap: 10px;

}

.rts___nav___btn {
  width: 31px;
  padding: 0 !important;
  height: 45px;
  vertical-align: super;
  border-radius: 6px !important;
  ;
  margin-top: 9px;
}

.rts___btn svg {
  display: none;
}

.rts___nav___btn {
  background: transparent url(../src/assets/images/left-chevron.png) no-repeat !important;
  background-position: 2px 11px !important;
  background-size: 23px !important;

}

.rts___tabs+.rts___nav___btn___container .rts___btn.rts___nav___btn {
  transform: scale(-1);
}

.rts___nav___btn {
  border: solid 1px #f9991e;
}

.list_m14_sup2 {
  column-gap: 30px;
}

.list_m14_sup2 li {
  border: solid 1px #ff9600;
  border-left: solid 10px #ff9600;
  box-shadow: 5px 6px #999693;
  margin-bottom: 30px;
}

.list_m14_sup2 li h3 {
  font-weight: 600;
  font-size: 20px;
  background: #ff9600;
  display: inline-block;
  color: lemonchiffon;
  padding: 4px 15px;
  margin: 6px 0 10px -11px;
  position: relative;
}

.list_m14_sup2 li h3::before {
  width: 20px;
  height: 20px;
  border-top: 16px solid transparent;
  border-left: 26px solid #ff9600;
  border-bottom: 16px solid transparent;
  content: '';
  display: inline-block;
  position: absolute;
  right: -25px;
  top: 0px;
}

.plr-100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}

#razorpay-form .PaymentButton.PaymentButton--light::before,
.PaymentButton-securedBy,
.PaymentButton.PaymentButton--light svg {
  display: none;
}

.PaymentButton.PaymentButton--light {
  font-style: inherit !important;
}

.PaymentButton--rzpTheme .PaymentButton-contents {
  padding: 0px !important;
  text-align: left;
  width: 128px;
}
.PaymentButton--rzp-light-standard.svelte-ekc7fv.svelte-ekc7fv {
  background: #fff;
  border-color: #fff;
  width: 128px;
  min-width: inherit !important;
}

.PaymentButton-text {
  background: #ff9600 !important;
  border: none !important;
  color: #fff !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  display: inline-block !important;
  margin-top: 2px;
}

#razorpay-form {
  min-width: 128px;
}
.plr-lg-100
{
  padding-left: 100px !important;
  padding-right: 100px !important;
}
.drp_m1 .dropdown
{
  border-bottom: solid 1px #ccc;
}
.video_gallery
{
  min-height: 550px;
}

@media (max-width: 1380px) {
  .header .navbar-nav>a, .header .navbar-nav .dropdown-toggle {
    font-size: 16px;
    margin: 0px;
}
  .nav__menu a {
    margin: 0px 7px !important;
  }

  .logo img {
    width: 70px !important;
  }
}

@media(max-width:1170px){
  .banner_m1 .carousel-item img {
    width: 100%;
    height: auto;
}
  .plr-lg-100
  {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 992px) {

  .video_gallery
  {
    min-height: 400px;
  }
  .sub_level_banner h1 {
    font-size: 30px !important;
  }

  .sub_level_banner h5 {
    font-size: 22px;
    margin-bottom: 20px;

  }

  .block__title {
    font-size: 2rem;
    margin-top: 40px;
  }

  .summary {
    max-width: 100% !important;
    margin-top: 0px !important;
    border-radius: 0 !important;
  }

  .banner_content1 {
    padding-left: 10px;
  }

  .header-section .nav__menu.navbar-nav {
    max-height: inherit !important;
  }

  .header-section .navbar-collapse {
    padding-bottom: 15px;
  }

  .steps_campaign li::before {
    right: -58px;
  }

  .steps_campaign li:nth-of-type(even)::before {
    left: -48px;
  }

  .circle_m4 {
    display: none;
  }

  .banner_m1 h2 {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .video_gallery
{
  min-height: 250px;
}

  .banner_m1 .carousel-item img {
      height: auto
   }

  .plr-100 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .banner_m1 h1 {
    font-size: 40px;
  }

  .list_m14 {
    column-count: 1;
  }

  .list_m15 li {
    padding-left: 10px;
    display: block;
  }

  .list_m15 li span {
    position: relative;
    overflow: hidden;
    left: inherit;
    padding: 5px 10px;
    margin-bottom: 5px;
  }

  .list_m15 li small:first-child {
    font-size: inherit;
    font-weight: inherit;
    background: transparent;
  }

  .list_m15 li small {
    display: inline-block;
  }

  .sub_level_banner {
    margin-top: 20px;

  }

  .tabs_m4 .some-animation-class {
    margin-top: 10px;
  }

  .sub_pages_banner::after {
    display: none;
  }

  .gride_m8 {
    width: 33%;
  }

  .donationAmountBtns,
  .donationBtns {
    display: inline-block !important;
  }

  .hero__scn {
    padding-top: 0 !important;
  }

  .btn_m5,
  .btn_m4,
  .btn_m3 {
    font-size: 14px !important;
  }

  .featured .swiper-button-prev,
  .featured .swiper-button-next {
    top: 90px;
  }

  .rts___tab.rts___btn.rts___tab___selected::before {
    left: 10px;

    top: 3px;
  }

  .rts___tab.rts___btn.rts___tab___selected {
    padding-left: 30px;
  }

  .slider_m2 .content_m3 {
    font-size: 15px;
  }

  .steps_campaign li:nth-of-type(even),
  .steps_campaign li:nth-of-type(odd) {
    width: calc(100% - 30px);
    margin: 10px 0 0 auto;
  }

  .steps_campaign::before {
    left: 3px;
  }

  .steps_campaign li::before {
    left: -40px !important;
    right: inherit;
  }

  .shape_m3 {
    margin-top: 0;
  }

  .banner_m1 {
    padding: 20px 0 65px 0;
  }

  .banner_m1 h2 {
    margin-bottom: 15px;
    font-size: 22px;
  }

  .input_m2 {
    margin-bottom: 15px !important
  }

  .form_m1 {
    padding: 20px 25px !important;
  }

  .font_24 {
    font-size: 18px;
  }

  .reg_section .block__title {
    font-size: 21px !important;
  }

  .block__title,
  .block__title .highlight {
    font-size: 30px !important;
  }
}

@media screen and (max-width: 567px) {
  .gride_m8 {
    width: 50%;
  }

  .cat_list1 {
    margin: 10px;
    min-height: 120px;
    min-width: 125px;
  }

  .logo h3 {
    font-size: 25px;
  }

  .logo p {
    font-size: 13px;
  }

  .header .navbar-toggler {
    padding: 2px 7px;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-collapse {
    /* height: 77px !important;
    display: inherit !important; */
  }
}

@media(max-width:991px) {
  .header-section .navbar-nav {
    padding-left: 0;
  }
}

@media(max-width:1200px) {

  .header .navbar-nav>a,
  .header .navbar-nav .dropdown-toggle {
    font-size: 16px;
    margin: 0 0px;
  }
}