.donate__fome{
    padding: var(--space-between-blocks);
    padding-bottom: var(--space-between-blocks);
}
.form_m1 {
  background: #FFFFED;
    padding: 60px 25px;
    /* box-shadow: 2px 12px 20px 9px #cccccc54; */
    max-width: 1100px;
    margin: 0 auto;
}
.input_m2 {
    margin-bottom: 50px;
}
.input_m2 label,
.label_m1 {
    display: block;
    margin-bottom: 0px;
    font-size: 15px;
    color: #313030;
    font-weight: 600;
}
.star_imp {
    color: #ff0000;
}
.input_m2 input, .input_m2 textarea ,
.input_m2 select{
  border: none;
  border-bottom: solid 1px #212222;
  background: transparent !important;
  outline: none !important;
  width: 100%;
}
.btn_m3{
  /* background: #ffffff!important;
  color: #ec2326 !important;
  padding: 5px 15px !important;
  border-radius: 30px !important;
  
  font-size: 16px !important;
  font-weight: 600 !important;
  border: solid 3px #ec2326  !important; */
}
.donate_img img{
    margin-bottom: -30px;
}
.donationBtns {
  text-align: center;
}

.donationBtns button{
  padding: 5px 36px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid #767373;
}
.donationAmountBtns{
  margin-top: 20px !important;
}
.donationAmountBtns button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid #767373;
  padding: 6px 26px;
  border-radius: 20px;
  margin-right: 20px;
}
.donationBtns button i{
  display: none;
}
.donationAmountBtns,
.donationBtns{
  display: flex;
  align-items: center;
  justify-content: center;
}

.donationBtns button.active i{
  display: block;
  background: #fff;
}
.donationBtns button.active{
  background: #fff;
}

.input_m2 p{
  color: #ff0000;
}
.input_m2sup
{
  position: relative;
}

.input_m2sup .sel_m4 {
  border: none;
  border-bottom: solid 2px;
  vertical-align: bottom;
  background: transparent;
  width: 62px;
  padding: 0;
  border: none;
  border-right: solid 1px #a5a5a5;
  position: absolute;
  top: 7px;
  height: 22px !important;
  font-weight: 700;
}
.input_m2sup input{
  padding-left: 67px;
}
.donate_input input{
  font-size: 23px;
  font-weight: 700;
}


@media screen and (max-width: 992px) {
    .donate__fome{
      padding: 4.3rem;
    }
  }
  
  
  
  @media screen and (max-width: 620px) {
    .donate__fome{
      padding: 1rem;
    }
  }
  
  @media screen and (max-width: 567px) {
    .donate__fome{
      padding: 0rem;
    }
  }