.MuiPaper-root{
    border-left: none !important;
    border-right: none !important;
    background-color: #fff !important;
}
.MuiButtonBase-root{
    background-color: #fff !important;
    padding: 14px 10px !important;
}
.MuiCollapse-root .MuiCollapse-entered,
.MuiPaper-root .Mui-expanded{
    background-color: #f5f5f5 !important;
    font-size: 30px !important;
}
.MuiAccordionDetails-root{
    background-color: #f5f5f5;
}
.MuiTypography-root{
    font-size: 18px !important;
}
