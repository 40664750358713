.footer {
    background: #fff !important;
    --text-primary: var(--block-text-color);
    background: var(--block-background);
    color: var(--block-text-color);
    font-size: .87rem;
    text-align: center;
    color: #000;
    border-top: solid 1px #ccc;
}
.footer h6{
    font-weight: 600;
}
a{
    text-decoration: none !important;
    color: unset !important;
}
.block-44__logo {
    height: 60px;
}
.block-44__logo-container {
    width: 100%;
}
.block-44__list {
    display: flex;
    flex-wrap: wrap;
}
.block-44__li-1 {
    margin: 0 1.8rem;
}
.block-44__link {
    color: var(--text-primary);
    opacity: .8 !important;
}
.footer .row {
    display: flex !important;
    
}
.footer__cont{
    border-bottom: 1px solid;
}






@media screen and (max-width: 992px) {
    .footer{
      padding: 4.3rem;
    }
    .footer .block-44__list{
        margin-top: 20px;
    }
  }
  
  
  
  @media screen and (max-width: 620px) {
    .footer{
      padding: 1rem;
    }
  }

  @media screen and (max-width: 767px) {
    .footer .block-44__list .block-44__li-1{
        margin: 0 1rem;
    }
  }
  
  @media screen and (max-width: 567px) {
    .footer{
      padding: 0rem;
    }
    .footer .block-44__list{
        display: inherit;
    }
    .footer .block-44__list{
        display: inherit;
    }
    .footer .block-44__list .block-44__li-1{
        padding-top: 20px;
    }
  }